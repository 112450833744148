<template>
  <div id="app">
    <transition name="fade" mode="out-in" appear>
      <Main v-if="isAuthorised" />
      <Login v-else />
    </transition>
    <YesNoCancel ref="YesNoCancel" @hook:mounted="setYNC" />
  </div>
</template>
<script>
  import popups from './components/popups'

  export default {
    name: 'App',
    components: {
        Login: () => import(/* webpackChunkName: "Login" */ './Login'),
        Main: () => import(/* webpackChunkName: "Main" */ './MainFrame'),
        YesNoCancel: () => import(/* webpackChunkName: "YesNoCancel" */ './modals/yesNoCancel.vue'),
    },
    computed: {
        isAuthorised() {
            return this.$store.state.session.session !== null
        }
    },
    mounted () {
    },
    methods: {
      setYNC() {
        popups.YNCRef = this.$refs.YesNoCancel
      }
    }
  }
</script>