import semver from 'semver'

const rxTxt = /\.\/(.+)\.txt$/
const files = require.context("./changelog", false, /(.+)\.txt$/)//("./" + expr + "")

export default class Changelog {
  constructor() {
    this.versions = []
    this.lastVersion = null
    this.loadVersions()
  }

  loadVersions() {
    for (let i of files.keys()) {
      let rxRes = rxTxt.exec(i)
      this.versions.push({
        version: rxRes[1],
        description: files(i)
      })
    }
    this.versions.sort(
      (a,b) => semver.rcompare(a.version, b.version)
    ) 
    this.lastVersion = this.versions[0].version
  }

}